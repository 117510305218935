<template>

    <PanelService>

        <h2>Enter your query</h2>

        <PanelForm>

            <label>Genetic variation:</label>
            <br/>

            <input type="text" required placeholder="BRAF (K601E)" :value="this.query.genetic_variation" v-on:change="isDbsnp"/>
            <span class="required"> *</span>
            <span v-show="query.genetic_variation == ''" class="required"> Genetic variation is required.</span>
            <br/>

            <span class="info">
                You can query for several variants, separated by and/or: <br/>
                <!--<a @click="loadExample('BRAF (V600K) OR P53 (P72R)')">BRAF (V600K) OR P53 (P72R)</a>, -->
                <a @click="loadExample('rs1042522')">rs1042522</a>,
                <a @click="loadExample('P53 (R213L)')">P53 (R213L)</a>, 
                <a @click="loadExample('rs3748093')">rs3748093</a>, 
                <a @click="loadExample('POLG1 (R722H)')">POLG1 (R722H)</a>, 
                <a @click="loadExample('NFE2L2 (D29H)')">NFE2L2 (D29H)</a>, 
                <a @click="loadExample('CYP1B1 (Ala115Pro) AND TEK (Glu103Asp)')">CYP1B1 (Ala115Pro) AND TEK (Glu103Asp)</a>
            </span>
            <br/><br/>

            <div class="row" v-if="in_progress">
                <PulseLoader id="progress-section" color="#D00000"/>
            </div>

            <div v-if="synvar_xml!=''"  class="file-description">

                <div v-if="variants.length > 0"  class="file-description">
                    <label>Suggested genetic variations:</label><br/>
                    <div v-for="(variant, index) in variants" v-bind:key=index>
                        <input class="mini-input"  :id="variant.term" :value="variant.term" name="variant" type="checkbox" v-model="query.rsid_variants" />
                        <label :for="variant.term">{{ variant.gene + " ("+variant.genomic }}<span v-if="variant.protein != null">, {{ variant.protein }}</span>)</label>
                    </div>
                    <span v-show="query.rsid_variants.length == 0" class="required"> At least one genetic variation must be selected.</span>
                </div>

                <div v-else>
                    <p>No variant identified with this dbSNP identifier</p>
                </div>

            </div>

        </PanelForm>

        <PatientForm/>
        <SettingsForm/>

    </PanelService>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import PanelService from '@/components/PanelService.vue'
    import PanelForm from '@/components/PanelForm.vue'
    import PatientForm from '@/components/PatientForm.vue'
    import SettingsForm from '@/components/SettingsForm.vue'
    var PulseLoader = require('vue-spinner/src/PulseLoader.vue').default;
    import axios from 'axios';

    export default {
      name: 'ServiceLit',
      components: {
        PanelService,
        PanelForm,
        PatientForm,
        SettingsForm,
        PulseLoader
      },
      data() {
        return {
            synvar_xml: "",
            in_progress: false,
            variants: [],
        }
      },
      computed: {
        ...mapState(['query', 'urls']),
      },
      methods: {
        ...mapActions(['updateGeneticVariation', 'updateRsid']),
        isDbsnp(event){
            this.updateGeneticVariation(event)
            this.variants = []
            this.synvar_xml = ""
            var re = new RegExp("^([rR][sS][0-9]+)$");
            if (re.test(this.query.genetic_variation)) {
                this.updateRsid(true)
                this.querySynvarAPI()
            }
            else {
                this.updateRsid(false)
            }
        },
        loadExample(example){
            this.updateGeneticVariation(example)
            this.isDbsnp(example)
            this.$matomo?.trackEvent("Select example", example);
        },
        load(){
            this.variants = []
            this.synvar_xml = ""
            var re = new RegExp("^([rR][sS][0-9]+)$");
            if (re.test(this.query.genetic_variation)) {
                this.updateRsid(true)
                this.querySynvarAPI()
            }
            else {
                this.updateRsid(false)
            }
        },
        async querySynvarAPI () {
           this.in_progress = true;
           var url = this.urls.synvar_url
           if (process.env.NODE_ENV != 'production'){
                url = this.urls.synvar_url_dev
            }
           url += this.query.genetic_variation
           try {
                const response = await axios.get(url)
                this.synvar_xml = response.data
                const parser = new DOMParser();
                const doc = parser.parseFromString(this.synvar_xml, "application/xml");
                for (var i=0; i < doc.documentElement.getElementsByTagName('variant').length; i++){
                    var variant_json = {}
                    var gene = "none"
                    var variant = doc.documentElement.getElementsByTagName('variant')[i]
                    if (variant.getElementsByTagName("gene-synonym-list").length > 0 ){
                        if (variant.getElementsByTagName("gene-synonym-list")[0].getElementsByTagName("synonym").length > 0){
                            gene = variant.getElementsByTagName("gene-synonym-list")[0].getElementsByTagName("synonym")[0].firstChild.nodeValue
                            variant_json.gene = gene
                        }
                    }
                    if (variant.getElementsByTagName("genome-level").length > 0 ){
                        var genome_level = variant.getElementsByTagName("genome-level")[0]
                        if (genome_level.getElementsByTagName("hgvs-list").length > 0 ){
                            var list = genome_level.getElementsByTagName("hgvs-list")[0]
                            if (list.getElementsByTagName("hgvs").length > 0) {
                                var variant_term = list.getElementsByTagName("hgvs")[0].firstChild.nodeValue
                                variant_json.term = gene +" ("+variant_term+")"
                                variant_json.genomic = variant_term
                            }
                        }
                    }
                    if (variant.getElementsByTagName("protein-level").length > 0 ){
                        var protein_level = variant.getElementsByTagName("protein-level")[0]
                        if (protein_level.getElementsByTagName("syntactic-variation-list").length > 0 ){
                            list = protein_level.getElementsByTagName("syntactic-variation-list")[0]
                            if (list.getElementsByTagName("syntactic-variation").length > 0) {
                                variant_term = list.getElementsByTagName("syntactic-variation")[0].firstChild.nodeValue
                                variant_json.protein = variant_term
                            }
                        }
                    }
                    if (Object.prototype.hasOwnProperty.call(variant_json, 'term') && gene != "none"){
                        // if ("protein" in variant_json){
                        //     variant_json.term = gene +" ("+variant_json.protein+")"
                        // }
                        this.variants.push(variant_json)
                        this.query.rsid_variants.push(variant_json.term)
                    }
                }
                this.in_progress = false
            } catch (e) {
                alert(e)
            }
        },
      },
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped slang="scss">

    input {
      width: 70%;
    }

</style>
