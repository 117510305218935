<template>


        <div class="container-fluid">

            <Banner />

            <div class="row">
                <ServiceChoice />
            </div>

            <div class="row">
                <ServiceLit ref="myChild" v-show="service_choice=='ranklit'"/>
                <ServiceVar v-show="service_choice=='rankvar'"/>
                <ServiceReload v-show="service_choice=='reload'"/>
            </div>

            <div class="row">
                <ConditionForm/>
                <ExecuteButton v-if="service_choice == 'ranklit'" @click="runSearch" button_text="Search"/>
                <ExecuteButton v-if="service_choice == 'rankvar'" @click="runSearch" button_text="Rank"/>
                <ExecuteButton v-if="service_choice == 'reload'" @click="runSearch" button_text="Reload"/>
            </div>

            <div class="row" v-if="in_progress">
                <PulseLoader id="progress-section" color="#D00000"/>
                <p><i>{{ query_status }}</i></p>
                <div class="warning">
                    <p>
                        <img class="icon" src="../assets/images/icon_warning.png"/><br/><br/>
                        Some queries can generate dozens of variant synonyms, resulting in a processing time up to five minutes.
                        If you do no want to wait for the results then copy the following link and come back later:<br/><br/>
                        <b> {{ variomes_gui }}?uniqueId={{ unique_id }}</b><br/><br/>
                        Thank you for using Variomes !
                    </p>
                </div>
            </div>

            <div class="row" v-if="variomes_json != null" >
                <h1 id="results-section">Results</h1>
            </div>

            <div class="row" v-if="variomes_json != null && variomes_json.data.length > 1">
                <DisplayVariants/>
            </div>

            <div class="row" v-if="variomes_json != null">
                <DisplayLiterature  id="literature-section"/>
           </div>

            <div class="row" v-if="variomes_json != null">
                <DisplayExport/>
            </div>

        </div>





</template>

<script>

    // @ is an alias to /src
    import { mapState, mapActions } from 'vuex'
    import Banner from '@/components/Banner.vue'
    import ServiceChoice from '@/components/ServiceChoice.vue'
    import axios from 'axios';
    import ServiceLit from '@/components/ServiceLit.vue'
    import ServiceVar from '@/components/ServiceVar.vue'
    import ServiceReload from '@/components/ServiceReload.vue'
    import ConditionForm from '@/components/ConditionForm.vue'
    import ExecuteButton from '@/components/ExecuteButton.vue'
    import DisplayVariants from '@/components/DisplayVariants.vue'
    var PulseLoader = require('vue-spinner/src/PulseLoader.vue').default;
    import DisplayLiterature from '@/components/DisplayLiterature.vue'
    import DisplayExport from '@/components/DisplayExport.vue'

    export default {
      name: 'Home',
      components: {
        Banner,
        ServiceChoice,
        ServiceLit,
        ServiceVar,
        ServiceReload,
        ConditionForm,
        ExecuteButton,
        PulseLoader,
        DisplayVariants,
        DisplayLiterature,
        DisplayExport
      },
      data () {
        return {
            in_progress: false
        }
      },
      computed: {
        ...mapState(['query_status', 'conditions_accepted', 'user_selection', 'service_choice', 'unique_id', 'urls', 'query', 'settings', 'variomes_json', 'filters']),
        variomes_gui (){
            if (process.env.NODE_ENV != 'production'){
                return this.urls.variomes_gui_dev
            }
            else {
                return this.urls.variomes_gui
            }
        }
      },
      methods: {
       ...mapActions(['updateQueryStatus', 'updateUniqueId', 'updateConditionsStatus', 'updateGeneticVariation', 'updateRsid', 'updateVCF', 'updateQueryId', 'resetResults', 'updateVariomesJson', 'updateConditionsStatus', 'updateServiceChoice', 'updateMinMax', 'updateMinDate', 'updateMaxDate']),
       runSearch() {
            this.defineUniqueId();
            if (this.conditions_accepted != true){
                this.updateConditionsStatus(false)
                alert("You must accept the conditions to use the service.")
            }
            else {
                if (this.service_choice == "ranklit" && (this.query.genetic_variation === null || this.query.genetic_variation == '')){
                    this.updateGeneticVariation('')
                    alert("You must enter a genetic variation.")
                }
                else if (this.service_choice == "ranklit" && this.query.rsid === true && this.query.rsid_variants.length == 0){
                    alert("You must select at least one genetic variation.")
                }
                else if (this.service_choice == "rankvar" && (this.query.vcf == null || this.query.vcf == "")){
                    this.updateVCF('')
                    alert("You must upload a file.")
                }
                else if (this.service_choice == "reload" && (this.query.id === null || this.query.id == "")){
                    this.updateQueryId('')
                    alert("You must enter a query identifier.")
                }
                else {
                    this.resetResults();
                    this.queryVariomesAPI()
                }
            }
        },
        defineUniqueId(){
            var id = this.query.id
            if (this.service_choice != "reload"){
                var dt = new Date().getTime();
                    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                        var r = (dt + Math.random()*16)%16 | 0;
                        dt = Math.floor(dt/16);
                        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
                    });
                    if (this.service_choice == "ranklit"){
                         id = this.query.genetic_variation.replace(/[^a-zA-Z0-9]+/g,'_') +"-"+ uuid
                    }
                    else if (this.service_choice == "rankvar"){
                        id = this.query.file_name.replace(/[^a-zA-Z0-9]+/g,'_') + "-" + uuid;
                    }
                    else {
                        id = uuid;
                    }
            }
            this.updateUniqueId(id);
            if (this.service_choice == "ranklit" || this.service_choice == "rankvar"){
                this.query.id = id
            }
        },
        async queryVariomesAPI () {
            this.in_progress = true;
            this.updateMinMax();
            var url = this.buildUrl()
            this.$matomo?.trackEvent("Query url", url);
            this.$matomo?.trackEvent("Query type", this.service_choice);
            this.$matomo?.trackEvent("Query", this.cleanQuery());
            this.$matomo?.trackEvent("Disease", this.query.disease !== "" ? this.query.disease : "null");
            this.$matomo?.trackEvent("Age", this.query.age !== "" ? this.query.age : "null");
            this.$matomo?.trackEvent("Gender", this.query.gender !== "" ? this.query.gender : "null");
            this.$matomo?.trackEvent("MinDate", this.settings.min_date !== "" ? this.settings.min_date : "null");
            this.$matomo?.trackEvent("MaxDate", this.settings.max_date !== "" ? this.settings.max_date : "null");
            this.$matomo?.trackEvent("MustDisease",this.settings.mandatory_disease == true ? "true" : "false");
            this.$matomo?.trackEvent("MustGene", this.settings.mandatory_gene == true ? "true" : "false");
            this.$matomo?.trackEvent("MustVariant", this.settings.mandatory_variant == true ? "true" : "false");
            this.$matomo?.trackEvent("ExpandDisease", this.settings.synonym_disease == true ? "true" : "false");
            this.$matomo?.trackEvent("ExpandGene", this.settings.synonym_gene == true ? "true" : "false");
            this.$matomo?.trackEvent("ExpandVariant", this.settings.synonym_variant == true ? "true" : "false");
            this.$matomo?.trackEvent("KeywordsPositive", this.settings.keywords_positive !== "" ? this.settings.keywords_positive : "none");
            this.$matomo?.trackEvent("KeywordsNegative", this.settings.keywords_negative !== "" ? this.settings.keywords_negative : "none");
            this.$matomo?.trackEvent("DocsNb", this.settings.nb_doc);
            this.$router.push({ name: 'Home', hash: '#progress-section', query: this.$route.query}).catch(()=>{});
            if (this.service_choice == "rankvar"){
                var count = this.query.vcf.split(";").length
                this.$matomo?.trackEvent("File size", count > 0 ? count : "0");
            }
            if((this.service_choice == "ranklit" && this.query.rsid === false) || this.service_choice == "reload"){
                 try {
                    axios.get(url)
                } catch (e) {
                    alert (e)
                }
            }
            else if (this.service_choice == "rankvar" || (this.service_choice == "ranklit" && this.query.rsid === true)){
                try {
                    var genvars_list = [];
                    if (this.service_choice == "rankvar"){
                        genvars_list = this.query.vcf.split(";");
                    }
                    if (this.service_choice == "ranklit"){
                        genvars_list= this.query.rsid_variants;
                    }
                    const genvars = { "genvars": genvars_list};
                    axios.post(url, genvars)
                    //alert(JSON.stringify(genvars))
                } catch (e) {
                    alert(e)
                }
            }
            // While processing is not finished, read status
            var url_status = this.urls.status_url;
            if (process.env.NODE_ENV != 'production'){
                url_status = this.urls.status_url_dev;
            }
            url_status += '?&uniqueId='+this.query.id;
            while (this.query_status != "Processing is finished, results will be displayed in a few seconds."){
                try {
                    const status_response = await axios.get(url_status)
                    this.updateQueryStatus(status_response.data.output)
                    if (this.query_status != "Processing is finished, results will be displayed in a few seconds."){
                        await new Promise(resolve => setTimeout(resolve, 30000));
                    }
                } catch (e) {
                    alert (e)
                }
            }
            // When processing is finished, display it
            try {
                const response = await axios.get(url)
                this.updateVariomesJson(response.data)
                for (var i=0; i<response.data.data.length; i++){
                    var results = response.data.data[i]["publications"]
                    for (const key in results) {
                        var countRes = results[key].length
                        this.$matomo?.trackEvent("Results count "+key, countRes > 0 ? countRes : "0 doc");
                    }
                }
                this.processFacets()
                this.in_progress = false
                this.$router.push({ name: 'Home', hash: '#results-section', query: this.$route.query}).catch(()=>{});
            } catch (e) {
                alert (e)
            }

        },
        buildUrl(){
            var url = this.urls.variomes_url
            if (process.env.NODE_ENV != 'production'){
                url = this.urls.variomes_url_dev
            }
            url += "?"
            if (this.service_choice == "ranklit" && this.query.rsid === false){
                url += 'genvars='+encodeURIComponent(this.query.genetic_variation)
            }
            if (this.service_choice == "ranklit" || this.service_choice == "rankvar"){
                url += '&disease='+ this.query.disease;
                url += '&gender='+ this.query.gender;
                url += '&age='+ this.query.age;
                url += '&collection='+this.settings.collections.join(",");
                url += '&minDate=' + this.settings.min_date;
                url += '&maxDate=' + this.settings.max_date;
                url += '&mustDisease=' + this.settings.mandatory_disease;
                url += '&mustGene=' + this.settings.mandatory_gene;
                url += '&mustVariant=' + this.settings.mandatory_variant;
                url += '&expandDisease=' + this.settings.synonym_disease;
                url += '&expandVariant=' + this.settings.synonym_variant;
                url += '&expandGene=' + this.settings.synonym_gene;
                url += '&keywordsPositive=' + this.settings.keywords_positive;
                url += '&keywordsNegative=' + this.settings.keywords_negative;
                url += "&uniqueId="+this.query.id;
                url += "&hl_fields=title,abstract,meshs,keywords,chemicals,detailed_description,inclusion_criteria";
                url += "&nb="+this.settings.nb_doc;
                url += '&gui'
            }
            if (this.service_choice == "reload"){
                url += "uniqueId="+this.query.id
            }
            return url
        },
        cleanQuery(){
            var clean_query = this.service_choice+"\tkl"
            if (this.service_choice == "ranklit" && this.query.rsid === false){
                clean_query += this.query.genetic_variation+"\t"
            }
            if (this.service_choice == "ranklit" || this.service_choice == "rankvar"){
                clean_query += this.query.disease+"\t"
                clean_query += this.query.gender+"\t"
                clean_query += this.query.age+"\t"
                clean_query += this.settings.min_date+"\t"
                clean_query += this.settings.max_date+"\t"
                clean_query += this.settings.mandatory_disease+"\t"
                clean_query += this.settings.mandatory_gene+"\t"
                clean_query += this.settings.mandatory_variant+"\t"
                clean_query += this.settings.synonym_disease+"\t"
                clean_query += this.settings.synonym_variant+"\t"
                clean_query += this.settings.synonym_gene+"\t"
                clean_query += this.settings.keywords_positive+"\t"
                clean_query += this.settings.keywords_negative+"\t"
                clean_query += this.settings.nb_doc;
            }
            if (this.service_choice == "reload"){
                clean_query += this.query.id+"\t"
            }
            return clean_query
        },
        processFacets(){

            // For each variant
            for (var v = 0; v < this.variomes_json.data.length; v++ ) {

                // For each collection
                for (var collection in this.variomes_json.data[v].publications) {

                    // For each publication
                    for (var p=0; p<this.variomes_json.data[v].publications[collection].length; p++){
                        var publication = this.variomes_json.data[v].publications[collection][p]

                        var processed_facets = {}

                        for (var f=0; f<this.filters.facets.length; f++){
                            var facet_name = this.filters.facets[f].short

                            var entities = []

                            // For named entities
                            if (facet_name in publication.details.facet_details){
                                for (var k =0; k<publication.details.facet_details[facet_name].length; k++){
                                    entities.push(publication.details.facet_details[facet_name][k].preferred_term)
                                }
                            }

                            // For publication related facets
                            else if (facet_name == "journal"){
                                if (facet_name in publication){
                                    entities.push(publication[facet_name])
                                }
                            }

                            // For publication related facets
                            else if (facet_name == "language"){
                                if (facet_name in publication){
                                    entities.push(publication[facet_name])
                                }
                            }

                            else if (facet_name == "publication_types") {
                                if (facet_name in publication){
                                    for (var t=0; t<publication[facet_name].length; t++){
                                        entities.push(publication[facet_name][t])
                                    }
                                }
                            }

                            // For sections
                            else if (facet_name == "sections"){
                                 for (var e =0; e<publication.evidences.length; e++){
                                    entities.push(publication.evidences[e].section)
                                }
                            }

                            // For clinical trials
                            else if (facet_name == "ct"){
                                 if (publication.details.information_extraction.clinical_trials.length > 0){
                                    entities.push("Clinical trial mentioned")
                                }
                            }
                             else if (facet_name == "pop"){
                                 if (publication.details.information_extraction.populations.length > 0){
                                    entities.push("Population mentioned")
                                }
                            }
                            else if (facet_name == "exclusive"){
                                 if (publication.collection_only == true){
                                    entities.push("Only in supp data")
                                }
                            }

                            // For dates
                            else if (facet_name == "date"){
                                if (facet_name in publication){
                                    entities.push(publication.date)
                                }
                            }

                            // Remove duplicates
                            var entities_unique = [...new Set(entities)];


                            processed_facets[facet_name] = entities_unique

                        }

                        this.variomes_json.data[v].publications[collection][p].processed_facets = processed_facets

                    }
                }

            }
        },
      },
      mounted() {
        'genvars' in this.$route.query ? this.query.genetic_variation = this.$route.query.genvars : null
        'disease' in this.$route.query ? this.query.disease = this.$route.query.disease : null
        'gender' in this.$route.query ? this.query.gender = this.$route.query.gender : null
        'age' in this.$route.query ? this.query.age = this.$route.query.age : null
        'uniqueId' in this.$route.query ? this.query.id = this.$route.query.uniqueId : null

        'minDate' in this.$route.query ? this.updateMinDate(this.$route.query.minDate) : null
        'maxDate' in this.$route.query ? this.updateMaxDate(this.$route.query.maxDate) : null
        'mustDisease' in this.$route.query ? this.settings.mandatory_disease = (this.$route.query.mustDisease =="true") : null
        'mustVariant' in this.$route.query ? this.settings.mandatory_variant = (this.$route.query.mustVariant =="true") : null
        'mustGene' in this.$route.query ? this.settings.mandatory_gene = (this.$route.query.mustGene =="true") : null
        'expandDisease' in this.$route.query ? this.settings.synonym_disease = (this.$route.query.expandDisease =="true") : null
        'expandVariant' in this.$route.query ? this.settings.synonym_variant = (this.$route.query.expandVariant =="true") : null
        'expandGene' in this.$route.query ? this.settings.synonym_gene = (this.$route.query.expandGene =="true") : null
        'keywordsPositive' in this.$route.query ? this.settings.keywords_positive = this.$route.query.keywordsPositive : null
        'keywordsNegative' in this.$route.query ? this.settings.keywords_negative = this.$route.query.keywordsNegative : null

        if ('genvars' in this.$route.query){

            var re = new RegExp("^([rR][sS][0-9]+)$");
            if (re.test(this.query.genetic_variation)) {
                    this.$refs.myChild.load()
            }
            else {
               this.updateConditionsStatus(true)
               this.runSearch()
            }
        }
        if ('uniqueId' in this.$route.query){
            this.updateServiceChoice("reload")
            this.updateConditionsStatus(true)
            this.runSearch()
        }
      },
    }

</script>

<style scoped lang="scss">

    .container-fluid{
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }

    .row {
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .warning {
        background-color: #FCD299;
        border-radius: 25px;
        padding: 20px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 90%

    }

    .icon {
        width: 20px;
        margin-right: 10px;
    }

</style>
