<template>

    <div>

        <p>{{ publications_processed.length }} document<span v-if="publications_processed.length > 1">s</span></p>
        <p v-if="publications_processed.length==0 && publications.length >0">No document retrieved with your filters. <a @click="removeAllFilters">Delete filters</a> to see all documents.</p>

        <DisplayDocument v-for="(item, index) in publications_processed.slice(itemMin, itemMax)" :key="item.id" :item="item" :variant="variant" :collection_title="collection_title" :collection_name="collection_name" :rank="index" :page="currentPage"/>

        <div class="page-box" @click="scrollUp()">
            <v-pagination v-model="currentPage" :page-count="pageTotal" :classes="bootstrapPaginationClasses" :labels="paginationAnchorTexts"></v-pagination>
        </div>

        <p v-if="showSize == false && publications.length > perPage"><a @click="showAll()">Show all documents</a></p>
        <p v-if="showSize == true && publications.length > 10"><a @click="showSome()">Show 10 documents per page</a></p>
        <a @click="scrollUp()"><img src="../assets/images/icon_top.png"/></a>

    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import DisplayDocument from '@/components/DisplayDocument.vue'
import shared from '@/components/shared.js'
import vPagination from 'vue-plain-pagination'

export default {
  name: 'DisplayPublicationTable',
  components: {
   DisplayDocument,
   vPagination
  },
  props: {
    publications: {
        type: Array,
        required: true
    },
    collection_name: {
        type: String,
        required: true
    },
    collection_title: {
        type: String,
        required: true
    },
    variant: {
        type: String,
        required: true
    }
  },
 data() {
    return {
        currentPage: 1,
        perPage: 10,
        showSize: false,
        bootstrapPaginationClasses: {
            ul: 'pagination',
            li: 'page-item',
            liActive: 'active',
            liDisable: 'disabled',
            button: 'page-link'
        },
        paginationAnchorTexts: {
            first: '<<',
            prev: '<',
            next: '>',
            last: '>>'
        }
    };
 },
 computed: {
    ...mapState(['user_selection', 'filters']),
    pageTotal(){
        var total = Math.ceil(this.publications_processed.length/this.perPage)
        this.updateCurrent(total)
       return total
    },
    publications_processed: function() {

        var copied_pub = this.publications

       // Sort documents
        if (this.user_selection.sort == "date"){
            copied_pub.sort((a, b) => parseFloat(b.date) - parseFloat(a.date));
        }
        if (this.user_selection.sort == "relevance"){
            copied_pub.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
        }

        // Filter facets
        copied_pub = copied_pub.filter(shared.filterThis.bind(this, this.user_selection.facets))

        return copied_pub;
    },
    itemMin(){
        return ((this.currentPage-1)*this.perPage)
    },
    itemMax(){
        return ((this.currentPage*this.perPage))
    }
 },
 methods: {
    ...mapActions(['updateFacet']),
    scrollUp(){
        this.$router.push({ name: 'Home', query: this.$route.query}).catch(()=>{});
        this.$router.push({ name: 'Home', hash: '#literature-section', query: this.$route.query}).catch(()=>{});
    },
    updateCurrent(total){
        if (this.currentPage > total){
            this.currentPage = total
        }
        if (this.currentPage == 0){
            this.currentPage = 1
        }
    },
    removeAllFilters(){
        this.$matomo?.trackEvent("Remove all facets ", "all");
        for (var key in this.user_selection.facets){
            this.updateFacet({'facet': key, 'list': [] })
        }
        this.updateFacet({'facet': 'date', 'list': this.filters.date })
    },
    showAll(){
        this.$matomo?.trackEvent("Documents displayed for "+this.collection_name, "all");
        this.perPage= this.publications.length;
        this.showSize = true
    },
    showSome(){
        this.$matomo?.trackEvent("Documents displayed for "+this.collection_name, "some");
        this.perPage= 10
        this.showSize = false
    }
 },
};
</script>

<style scoped lang="scss">

    .page-box {
      display: flex;
      justify-content: center;
    }

    img {
        width: 25px;
    }

</style>