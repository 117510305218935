<template>

    <div>

        <h3>Select your type of query</h3>

        <div class="btn-group" role="group">
            <input type="radio" name="service_type" class="btn-check" id="ranklit" value="ranklit" v-model="service_choice">
            <label class="btn btn-outline-danger label-choice" for="ranklit">Search literature for one variant</label>

            <input type="radio" name="service_type" class="btn-check" id="rankvar" value="rankvar" v-model="service_choice">
            <label class="btn btn-outline-danger label-choice" for="rankvar">Prioritize a set of variants</label>

            <input type="radio" name="service_type" class="btn-check" id="reload" value="reload" v-model="service_choice">
            <label class="btn btn-outline-danger label-choice" for="reload">Reload previous results</label>
        </div>

    </div>

</template>

<script>

    import { mapActions } from 'vuex'

    export default {
      name: 'ServiceChoice',
      computed: {
         service_choice: {
            get() { return this.$store.getters.getServiceChoice; },
            set(updated_choice) { 
                this.$store.dispatch('updateServiceChoice', updated_choice); 
                this.$matomo?.trackEvent("Service choice", updated_choice);
            }
         }
      },
      methods: {
        ...mapActions(['updateServiceChoice'])
      },
    }

</script>

<style scoped lang="scss">

    .label-choice {
        width: 260px;
    }

</style>
