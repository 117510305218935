<template>

    <span v-if="entityExist()" :class="entityStatus()" :title="entitySubtitle()">{{ entity_name }}</span>

</template>

<script>

    export default {
      name: 'DisplayStatus',
      props: {
        entity_name: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
      },
      methods: {
        entityExist(){
            if ('query_'+this.entity_name+'_present' in this.item.details.query_details){
                if (this.item.details.query_details.['query_'+this.entity_name+'_present'].total > 0){
                    return true
                }
            }
            else if ('query_'+this.entity_name  in this.item.details.query_details){
                return true
            }
            return false
        },
        entityStatus(){
            if ('query_'+this.entity_name+'_present' in this.item.details.query_details){
                if (this.item.details.query_details.['query_'+this.entity_name+'_present'].present > 0){
                    if (this.item.details.query_details.['query_'+this.entity_name+'_present'].absent > 0){
                        return "partial"
                    }
                    else{
                        return "present"
                    }
                }
            }
            else if ('query_'+this.entity_name  in this.item.details.query_details){
                if (this.item.details.query_details['query_'+this.entity_name].includes("same")){
                    return "present";
                }
                else if (this.item.details.query_details['query_'+this.entity_name].includes("not discussed")){
                    return "partial"
                }
            }
            return "absent"
        },
        entitySubtitle(){
            if ('query_'+this.entity_name+'_present' in this.item.details.query_details){
                if (this.item.details.query_details.['query_'+this.entity_name+'_present'].present > 0){
                    if (this.item.details.query_details.['query_'+this.entity_name+'_present'].absent > 0){
                        return "Genes present in the paper: "+this.item.details.query_details.['query_'+this.entity_name+'_present'].present+"/"+this.item.details.query_details.['query_'+this.entity_name+'_present'].total
                    }
                    else {
                        if (this.item.details.query_details.['query_'+this.entity_name+'_present'].total == 1){
                            return "The "+this.entity_name+" is present in the paper"
                        }
                        else {
                            return "The "+this.entity_name+"s are present in the paper"
                        }
                    }
                }
                else {
                    if (this.item.details.query_details.['query_'+this.entity_name+'_present'].total == 1){
                                return "The "+this.entity_name+" is absent in the paper"
                           }
                           else {
                                return "The "+this.entity_name+"s are absent in the paper"
                           }
                }
            }
            else if ('query_'+this.entity_name  in this.item.details.query_details){
                return this.item.details.query_details['query_'+this.entity_name]
            }
            return ""
        },
      }
    }

</script>

<style scoped lang="scss">

    .present, .absent, .partial {
        display: inline-block;
        padding: 1px;
        margin-right: 5px;
        text-align: center;
        border-radius: 10px;
        width: 70px;
        color: #FFFFFF;
        font-size: 0.8em;
    }
    .present {
        background-color: #70AD47;
    }
    .absent {
        background-color: #C00000;
    }
    .partial {
        background-color: #ED7D31;
    }

</style>
