export default {
  filterThis(facets, publication){

    var status = true;

    var multi = ['language', 'sections']



    // Filter documents
    for (const key in facets) {

        if (multi.includes(key)){

            var list0 = facets[key]
            //alert(list0)
            var thisStatus0 = false;

            if (list0.length == 0){
                thisStatus0 = true;
            }

            for (var i0=0; i0<list0.length; i0++){

                for (var f0=0; f0<publication.processed_facets[key].length; f0++){
                    if (publication.processed_facets[key][f0] == list0[i0]){
                        thisStatus0 = true;
                        break;
                    }
                }

            }

            if (!thisStatus0){
                status = false;
                break;
            }

        }

        else if (key != "date"){

            var list = facets[key]

            for (var i=0; i<list.length; i++){
                var thisStatus = false;

                for (var f=0; f<publication.processed_facets[key].length; f++){
                    if (publication.processed_facets[key][f] == list[i]){
                        thisStatus = true;
                    }
                }

                if (!thisStatus){
                    status = false
                    break;
                }
            }

        }

        else {

            var minmax = facets[key]

            if (minmax.length > 0){
                for (var f2=0; f2<publication.processed_facets[key].length; f2++){
                    var thisStatus2 = false;
                    if (publication.processed_facets[key][f2] >= minmax[0] && publication.processed_facets[key][f2] <= minmax[1]){
                        thisStatus2 = true;
                    }
                    if (!thisStatus2){
                        status = false
                        break;
                    }
                }
            }

        }

    }

     return status;

  },
  highlight(text, highlights, facets){
    for(var i=0; i<highlights.length; i++){
        var regex = 'class="'+highlights[i]+'"'
        text = text.replaceAll(regex, 'style="color:#D00000"')
    }
    if (facets['pop'].length > 0){
        var regex2 = 'class="populations"'
        text = text.replaceAll(regex2, 'style="font-weight:bold"')
    }
    if (facets['ct'].length > 0){
        var regex3 = 'class="clinical_trials"'
        text = text.replaceAll(regex3, 'style="font-weight:bold"')
    }
    return text
  },
}