<template>

    <div class="component-container">

        <div class="row header">
             <div class="col-1">Rank</div>
             <div class="col-3">Genetic variation</div>
             <div class="col-1">Score</div>
             <div class="col-1">MEDLINE citations</div>
             <div class="col-1">PubMed Central</div>
             <div class="col-1">Clinical trials</div>
             <div class="col-1">Supplementary data</div>
             <div class="col-3"></div>
        </div>

        <div v-for="(item, index) in this.variomes_json.data.slice(itemMin, itemMax)" :key="index">

            <div class="row variant-card" :class="{odd: index % 2 == 0, even: index % 2 != 0}">
                <div class="col-1">{{ ((currentPage-1)*perPage+index+1) }}</div>
                <div class="col-3">{{ item.query.genvars }}</div>
                <div class="col-1">{{ item.total_score }}</div>
                <div class="col-1">{{ item.count_medline }}</div>
                <div class="col-1">{{ item.count_pmc }}</div>
                <div class="col-1">{{ item.count_ct }}</div>
                <div class="col-1">{{ item.count_supp }}</div>
                <div class="col-3"><button @click="scrollDown();updateVariantDisplayed((currentPage-1)*perPage+index);logVariantDisplay((currentPage-1)*perPage+index+1, item.query.genvars)">See publications</button></div>
            </div>

        </div>
        <br/>

        <div class="page-box">
            <v-pagination v-model="currentPage" :page-count="pageTotal" :classes="bootstrapPaginationClasses" :labels="paginationAnchorTexts"></v-pagination>
        </div>

        <br/><br/>

    </div>

</template>

<script>

    import vPagination from 'vue-plain-pagination'
    import { mapState, mapActions } from 'vuex'

    export default {
      name: 'VariantsDisplay',
      components: {
        vPagination
      },
      props: {
        publications: [],
      },
     data() {
        return {
            currentPage: 1,
            perPage: 10,
            bootstrapPaginationClasses: {
            ul: 'pagination',
            li: 'page-item',
            liActive: 'active',
            liDisable: 'disabled',
            button: 'page-link'
          },
          paginationAnchorTexts: {
            first: '<<',
            prev: '<',
            next: '>',
            last: '>>'
          }
        };
     },
     computed: {
        ...mapState(['variomes_json']),
        pageTotal(){
           return Math.ceil(this.variomes_json.data.length/this.perPage)
        },
        itemMin(){
            return ((this.currentPage-1)*this.perPage)
        },
        itemMax(){
            return ((this.currentPage*this.perPage))
        },
     },
     methods: {
        ...mapActions(['updateVariantDisplayed']),
        scrollDown(){
             this.$router.push({ name: 'Home', hash: '#variants-section', query: this.$route.query});
             this.$router.push({ name: 'Home', hash: '#literature-section', query: this.$route.query});
        },
        logVariantDisplay(position, variant){
          this.$matomo?.trackEvent("Variant list", position+";"+variant);
        }
     }
    };

</script>


<style scoped lang="scss">


    .row {
      border: 1px solid #ddd;
      padding: 6px;
      text-align: center;
    }

    .header {
      padding-top: 6px;
      padding-bottom: 6px;
      text-align: center;
      background-color: #D00000;
      color: white;
      font-weight: bold;
    }

    .variant-card:hover{
     background-color: #ddd;
    }

    .odd {
      background-color:#ffffff;
    }

    .even {
      background-color:#f2f2f2;
    }

    button {
      display: inline-block;
      border-radius: 10px;
      background-color: #AAA;
      border: none;
      color: #FFFFFF;
      text-align: center;
      padding: 5px 10px;
      cursor: pointer;
    }

    button:hover {
      color: #DDD;
    }

    .page-box {
      display: flex;
      justify-content: center;
    }



</style>
