<template>

    <PanelHideShow :expanded_init="false" size="small" log_name="Patient options">

        <template v-slot:title>Patient information</template>

        <template v-slot:content>

            <PanelForm>

                <p class="info">You can optionally submit additional information about your patient to refine the search.</p>

                <label>Disease:</label>
                <input type="text" :v-model="query.disease" :value="query.disease" @change="updateDisease"/>
                <br/>

                <label>Gender:</label>
                <select :v-model="query.gender" :value="query.gender" @change="updateGender">
                    <option value="none"></option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                </select>
                <br/>

                <label>Age:</label>
                <input type="number" min="0" max="120" :v-model="query.age" :value="query.age" @change="updateAge"/>
                <br/>

            </PanelForm>

        </template>

    </PanelHideShow>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import PanelHideShow from '@/components/PanelHideShow.vue'
    import PanelForm from '@/components/PanelForm.vue'

    export default {
      name: 'PatientForm',
      components: {
        PanelHideShow,
        PanelForm
      },
      computed: {
        ...mapState(['query']),
      },
      methods: {
        ...mapActions(['updateDisease', 'updateAge', 'updateGender'])
      }
    }

</script>

<style scoped lang="scss">


</style>
