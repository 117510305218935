<template>

    <div id="app">

        <div id="nav">
          <router-link to="/">Home</router-link> |
          <router-link to="/tutorial">Tutorial</router-link> |
          <router-link to="/about">About</router-link> |
          <router-link to="/apis">APIs</router-link> |
          <router-link to="/privacy">Privacy</router-link>
        </div>

        <router-view/>

        <div class="logo">
            <img src="./assets/images/logo_sib.png"/>
            <img src="./assets/images/logo_heg.png"/>
            <img src="./assets/images/logo_hesso.jpg"/>
            <img src="./assets/images/logo_sibils.png"/>
            <img src="./assets/images/logo_svip.png"/>
            <img src="./assets/images/logo_sphn.png"/>
            <img src="./assets/images/logo_elixir.png"/>
        </div>

    </div>

</template>

<script>

  export default {
    
    mounted() {
      let script1 = document.createElement('script')
      script1.setAttribute('src', 'https://candy.hesge.ch/pmca/src/proxy-data-access.js')
      script1.setAttribute('crossorigin', 'anonymous')
      document.head.appendChild(script1)      
      let script2 = document.createElement('script')
      script2.setAttribute('src', 'https://candy.hesge.ch/pmca/src/proxy-data-parser.js')
      script2.setAttribute('crossorigin', 'anonymous')
      document.head.appendChild(script2)
      let script3 = document.createElement('script')
      script3.setAttribute('src', 'https://candy.hesge.ch/pmca/node_modules/@webcomponents/webcomponentsjs/webcomponents-loader.js')
      document.head.appendChild(script3)
      let script4 = document.createElement('script')
      script4.setAttribute('src', 'https://candy.hesge.ch/pmca/src/pmca-element.js')
      script4.setAttribute('type', 'module')
      script4.setAttribute('crossorigin', 'anonymous')
      document.head.appendChild(script4)      
    },
  }

</script>

<style lang="scss">


    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;

      .component-container {
        width: 90%;
        margin: 0 auto;
      }

      a {
        font-weight: bold;
        text-decoration: none;
        color: #D00000;
      }
      a:hover {
        text-decoration: underline;
      }

      h1 {
        font-size: 1.8em;
        padding: 20px 0;
        margin: 0;
      }
      h2 {
        font-size: 1.2em;
        padding: 15px 0;
        margin: 0;
      }
      h3 {
        font-size: 1.1em;
        padding: 10px 0;
        margin: 0;
      }

      .info {
        font-style: italic;
        font-size: 0.8em;
      }
      .required {
        color: red;
      }
      .nav-pills .tab-title .nav-link:not(.active) {
        background-color: #FFFFFF !important;
        color: #000000;
        font-weight: normal;
      }

      .nav-pills .tab-title .nav-link {
        background-color: #D00000 !important;
        color: #fff;
        text-decoration: none;
        font-weight: normal;
      }

      .page-item .page-link {
        color: #2c3e50;
      }

      .page-item .page-link:hover {
        color: #2c3e50;
      }
      .page-item.active .page-link {
        color: #fff !important;
        background: #D00000 !important;
        border: 0px solid #D00000;
      }

      .page-item.active {
        color: #fff !important;
        background: #D00000 !important;
        border: 1px solid #D00000;
      }

      .logo {
        margin-top: 50px;
        border-top: 1px solid #D00000;
        border-bottom: 1px solid #D00000;
      }
      .logo img {
        height: 90px;
        padding: 20px;
      }

    }


    #nav {

      padding: 30px;

      a {
        font-weight: bold;
        color: #2c3e50;
        text-decoration: none;

        &.router-link-exact-active {
          color: #D00000;
        }
      }
      a:hover {
        text-decoration: underline;
      }

    }

</style>
