import { render, staticRenderFns } from "./Bookmark.vue?vue&type=template&id=dd7e5046&scoped=true&"
import script from "./Bookmark.vue?vue&type=script&lang=js&"
export * from "./Bookmark.vue?vue&type=script&lang=js&"
import style0 from "./Bookmark.vue?vue&type=style&index=0&id=dd7e5046&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd7e5046",
  null
  
)

export default component.exports