import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    urls: {
        variomes_gui_dev: "https://variomes.sibils.org/",
        variomes_url_dev: "https://variomes.sibils.org/apitest/rankVar",
        status_url_dev: "https://variomes.sibils.org/apitest/getStatus",
        synvar_url_dev: "https://synvar.sibils.org/generate/litterature/fromMutation?variant=",
        variomes_gui: "https://variomes.sibils.org/",
        variomes_url: "https://variomes.sibils.org/api/rankVar",
        status_url: "https://variomes.sibils.org/api/getStatus",
        synvar_url: "https://synvar.sibils.org/generate/litterature/fromMutation?variant=",
    },
    service_choice: "ranklit",
    unique_id: "",
    conditions_accepted: null,
    query_status: "",
    query: {
        genetic_variation: null,
        vcf: null,
        file_name: null,
        rsid: false,
        rsid_variants: [],
        disease: "",
        gender: "",
        age: "",
        id: null
    },
    settings: {
        min_date: 2014,
        max_date: 2024,
        nb_doc: 1000,
        mandatory_disease: true,
        mandatory_variant: true,
        mandatory_gene: true,
        synonym_disease: true,
        synonym_variant: true,
        synonym_gene: true,
        keywords_positive: "",
        keywords_negative: "",
        collections: ["medline", "pmc", "ct", "supp"]
    },
    variomes_json: null,
    facets_json: null,
    user_selection: {
        current_page: {
            'medline': 1,
            'pmc' : 1,
            'ct': 1
        },
        document_count: 0,
        variant_count: 0,
        publications: {},
        variant_displayed: 0,
        facets: {
            drugs: [],
            genes: [],
            diseases: [],
            gender_groups: [],
            age_groups: [],
            journal: [],
            publication_types: [],
            sections: [],
            pop: [],
            ct: [],
            date: [],
            language: [],
            exclusive: []
        },
        highlights: ['variant'],
        sort: 'relevance',
    },
    filters: {
        sort: ['relevance', 'date'],
        facets: [
            {title: 'Date', short: 'date', multi: false},
            {title: 'Sections', short: 'sections', multi: true},
            {title: 'Languages', short: 'language', multi: true},
            {title: 'Drugs', short: 'drugs', multi: false},
            {title: 'Diseases', short: 'diseases', multi: false},
            {title: 'Genes', short: 'genes', multi: false},
            {title: 'Genders', short: 'gender_groups', multi: false},
            {title: 'Ages', short: 'age_groups', multi: false},
            {title: 'Journals', short: 'journal', multi: false},
            {title: 'Publication types', short: 'publication_types', multi: false},
            {title: 'Clinical Trial ID', short: 'ct', multi: false},
            {title: 'Size of population', short: 'pop', multi: false},
            {title: 'Exclusive', short: 'exclusive', multi: false},
        ],
        highlights: ['gene', 'variant', 'disease', 'gender', 'age'],
        date: []
    }
  },
  getters: {
    getServiceChoice: state => { return state.service_choice;},
    getConditionsStatus: state => { return state.conditions_accepted;},
  },
  mutations: {
    UPDATE_SERVICE(state, service_selected) {
        state.service_choice = service_selected
    },
    UPDATE_UNIQUE_ID(state, id) {
        state.unique_id = id
    },
    UPDATE_QUERY_ID(state, id) {
        state.query.id = id
    },
    UPDATE_GENETIC_VARIATION(state, genetic_variation) {
        state.query.genetic_variation = genetic_variation
    },
    UPDATE_RSID(state, rsid) {
        state.query.rsid = rsid
    },
    UPDATE_RSID_VARIANTS(state) {
        state.query.rsid_variants = []
    },
    UPDATE_VCF(state, vcf){
        state.query.vcf = vcf
    },
    UPDATE_DISEASE(state, disease) {
        state.query.disease = disease
    },
    UPDATE_AGE(state, age) {
        state.query.age = age
    },
    UPDATE_GENDER(state, gender) {
        state.query.gender = gender
    },
    UPDATE_MIN_DATE(state, date) {
        state.settings.min_date = date
    },
    UPDATE_MAX_DATE(state, date) {
        state.settings.max_date = date
    },
    UPDATE_NB_DOC(state, nb) {
        state.settings.nb_doc = nb
    },
    UPDATE_MANDATORY_DISEASE(state, status){
        state.settings.mandatory_disease = status
    },
    UPDATE_MANDATORY_GENE(state, status){
        state.settings.mandatory_gene = status
    },
    UPDATE_MANDATORY_VARIANT(state, status){
        state.settings.mandatory_variant = status
    },
    UPDATE_SYNONYM_DISEASE(state, status){
        state.settings.synonym_disease = status
    },
    UPDATE_SYNONYM_GENE(state, status){
        state.settings.synonym_gene = status
    },
    UPDATE_SYNONYM_VARIANT(state, status){
        state.settings.synonym_variant = status
    },
    UPDATE_KEYWORDS_POSITIVE(state, keywords){
        state.settings.keywords_positive = keywords
    },
    UPDATE_KEYWORDS_NEGATIVE(state, keywords){
        state.settings.keywords_negative = keywords
    },
    UPDATE_CONDITIONS(state, status){
        state.conditions_accepted = status
    },
    UPDATE_VARIOMES_JSON(state, data){
        state.variomes_json = data
    },
    UPDATE_SELECTED_PUBLICATIONS(state, value){
        state.user_selection.publications = value
    },
    UPDATE_DOCUMENT_COUNT(state, value){
        state.user_selection.document_count = value
    },
    UPDATE_VARIANT_COUNT(state, value){
        state.user_selection.variant_count = value
    },
    UPDATE_VARIANT_DISPLAYED(state, value){
        state.user_selection.variant_displayed = value
    },
    UPDATE_SORT(state, value){
        state.user_selection.sort = value
    },
    UPDATE_HIGHLIGHT(state, value){
        state.user_selection.highlights = value
    },
    UPDATE_FACET(state, value){
        state.user_selection.facets[value.facet] = value.list
    },
    UPDATE_FILTER_DATE(state, value){
        state.filters.date = value
    },
    UPDATE_QUERY_STATUS(state, value){
        state.query_status = value
    },
  },
  actions: {
    updateServiceChoice(context, value) {
        context.commit('UPDATE_SERVICE', value)
    },
    updateConditionsStatus(context, value) {
        context.commit('UPDATE_CONDITIONS', value)
    },
    updateUniqueId(context, value) {
        var this_value = value
        if (this_value == ""){
            this_value = "none"
        }
        context.commit('UPDATE_UNIQUE_ID', this_value)
    },
    updateQueryId(context, event) {
        var this_value = ''
        if (typeof event === 'string'){
            this_value = event
        }
        else {
            this_value = event.target.value
        }
        context.commit('UPDATE_QUERY_ID', this_value)
    },
    updateGeneticVariation(context, event) {
        var this_value = ''
        if (typeof event === 'string'){
            this_value = event
        }
        else {
            this_value = event.target.value
        }

        this_value = this_value.trim()
        context.commit('UPDATE_GENETIC_VARIATION', this_value)
    },
    updateRsid(context, event) {
        var this_value = ''
        if (typeof event === 'boolean'){
            this_value = event
        }
        else {
            this_value = event.target.value
        }
        context.commit('UPDATE_RSID', this_value)
        context.commit('UPDATE_RSID_VARIANTS')
    },
    updateVCF(context, vcf_value){
        context.commit("UPDATE_VCF", vcf_value)
    },
    updateDisease(context, event) {
        var this_value = event.target.value
        if (this_value == ""){
            this_value = "none"
        }
        context.commit('UPDATE_DISEASE', this_value)
    },
    updateAge(context, event) {
        var this_value = event.target.value;
        if (this_value == ""){
            this_value = "none";
        }
        else if (this_value > 120){
            this_value = 120;
        }
        else if (this_value < 0){
            this_value = 0;
        }
        context.commit('UPDATE_AGE', this_value)
    },
    updateGender(context, event) {
        context.commit('UPDATE_GENDER', event.target.value)
    },
    updateMinDate(context, event){
        var min_value = 0
        if (typeof event === 'string'){
            min_value = parseInt(event)
        }
        else {
            min_value = parseInt(event.target.value)
        }
        if (min_value > this.state.settings.max_date){
            context.commit("UPDATE_MIN_DATE", this.state.settings.max_date)
        }
        else {
            context.commit("UPDATE_MIN_DATE", min_value)
       }
    },
    updateMaxDate(context, event){
        var max_value = 0
        if (typeof event === 'string'){
            max_value = parseInt(event)
        }
        else {
            max_value = parseInt(event.target.value)
        }
        if (max_value < this.state.settings.min_date){
            context.commit("UPDATE_MAX_DATE", this.state.settings.min_date)
        }
        else {
            context.commit("UPDATE_MAX_DATE", max_value)
        }
     },
     updateNbDoc(context, event){
        var nb = 0
        if (typeof event === 'string'){
            nb = parseInt(event)
        }
        else {
            nb = parseInt(event.target.value)
        }
        context.commit("UPDATE_NB_DOC", nb)
    },
    updateMinMax(context){
       context.commit('UPDATE_FACET', {'facet': 'date', 'list': [this.state.settings.min_date, this.state.settings.max_date]})
       context.commit('UPDATE_FILTER_DATE', [this.state.settings.min_date, this.state.settings.max_date])
    },
    updateMandatoryDisease(context, event){
        context.commit('UPDATE_MANDATORY_DISEASE', event.target.checked)
    },
    updateMandatoryGene(context, event){
        context.commit('UPDATE_MANDATORY_GENE', event.target.checked)
    },
    updateMandatoryVariant(context, event){
        context.commit('UPDATE_MANDATORY_VARIANT', event.target.checked)
    },
    updateSynonymDisease(context, event){
        context.commit('UPDATE_SYNONYM_DISEASE', event.target.checked)
    },
    updateSynonymGene(context, event){
        context.commit('UPDATE_SYNONYM_GENE', event.target.checked)
    },
    updateSynonymVariant(context, event){
        context.commit('UPDATE_SYNONYM_VARIANT', event.target.checked)
    },
    updateKeywordsPositive(context, event) {
        context.commit('UPDATE_KEYWORDS_POSITIVE', event.target.value)
    },
    updateKeywordsNegative(context, event) {
        context.commit('UPDATE_KEYWORDS_NEGATIVE', event.target.value)
    },
    updateVariomesJson(context, value) {
        context.commit('UPDATE_VARIOMES_JSON', value)
    },
    updateQueryStatus(context, value){
        context.commit('UPDATE_QUERY_STATUS', value)
    },
    addBookmarkedPublication(context, data) {
        var publications = this.state.user_selection.publications
         if (!(data.variant in publications)){
            publications[data.variant] = {}
        }
        if (!(data.collection in publications[data.variant])){
            publications[data.variant][data.collection] = {}
        }
        publications[data.variant][data.collection][data.document] = data.item
        context.commit('UPDATE_SELECTED_PUBLICATIONS', publications)
        context.commit('UPDATE_DOCUMENT_COUNT', this.state.user_selection.document_count + 1)
        context.commit('UPDATE_VARIANT_COUNT', Object.keys(publications).length)
    },
    removeBookmarkedPublication(context, data) {
        var publications = this.state.user_selection.publications
        delete publications[data.variant][data.collection][data.document]
        if (Object.keys(publications[data.variant][data.collection]).length == 0){
            delete publications[data.variant][data.collection]
        }
        if (Object.keys(publications[data.variant]).length == 0){
            delete publications[data.variant]
        }
        context.commit('UPDATE_SELECTED_PUBLICATIONS', publications)
        context.commit('UPDATE_DOCUMENT_COUNT', this.state.user_selection.document_count - 1)
        context.commit('UPDATE_VARIANT_COUNT', Object.keys(publications).length)
    },
    resetResults(context) {
        context.commit('UPDATE_VARIOMES_JSON', null)
        context.commit('UPDATE_SELECTED_PUBLICATIONS', {})
        context.commit('UPDATE_DOCUMENT_COUNT', 0)
        context.commit('UPDATE_VARIANT_COUNT', 0)
        context.commit('UPDATE_VARIANT_DISPLAYED', 0)
        context.commit('UPDATE_QUERY_STATUS', '')
    },
    updateVariantDisplayed(context, value) {
        context.commit('UPDATE_VARIANT_DISPLAYED', value)
    },
    updateSort(context, value) {
        context.commit('UPDATE_SORT', value)
    },
    updateHighlight(context, value) {
        context.commit('UPDATE_HIGHLIGHT', value)
    },
    updateFacet(context, value) {
        context.commit('UPDATE_FACET', value)
    },
  },
  modules: {
  }
})
