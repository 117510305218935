<template>

    <div class="facets">

        <div class="allfilters" v-if="visible_facets">

            <h2>Sort and highlight</h2>

            <PanelHideShow :expanded_init="false" size="small">
                <template v-slot:title>Sort</template>
                <template v-slot:content>
                    <div class="inputGroup" v-for="sort in filters.sort" @click="updatePreSort(sort, $event)" :key="sort">
                        <input type="radio" :id="sort" :name="sortGroupName" :value="sort" v-model="user_selection.sort"/>
                        <label :for="sort">By {{ sort }}</label>
                    </div>
                </template>
            </PanelHideShow>

            <PanelHideShow :expanded_init="false" size="small">
                <template v-slot:title>Highlight</template>
                <template v-slot:content>
                    <div class="inputGroup" v-for="highlight in highlight_list" @click="changeHighlight($event)" :key="highlight.name">
                        <div v-if="shouldHighlight(highlight.name)">
                            <input :id="highlight.name" :name="highlight.name" type="checkbox" v-bind:value="highlight.name" :checked="highlight.checked"/>
                            <label :for="highlight.name">{{ highlight.name }}</label>
                        </div>
                    </div>
                </template>
            </PanelHideShow>

            <br/>

            <h2>Filters</h2>

            <div v-for="facet in filters.facets" :key="facet.short">

                <PanelHideShow :expanded_init="true" size="small" v-if="checkedFacets(facet).length > 0">

                    <template v-slot:title>{{ facet.title }}</template>
                    <template v-slot:content>

                    <PanelMoreLess v-if="facet.multi == true" :visible_length="5" :items_length="checkedMulti(facet).length" message_more="More" message_less="Less">

                        <template slot="default-list">
                            <div class="inputGroup" v-for="item in checkedMulti(facet).slice(0, 5)" @click="changeFacet(facet, $event)" :key="item.name">
                                <input :id="item.name" :name="item.name" type="checkbox" v-bind:value="item.name" :checked="item.checked"/>
                                <label :for="item.name">{{ item.name }} ({{ item.count }})</label>
                            </div>
                        </template>

                        <template slot="extra-list">
                            <div class="inputGroup" v-for="item in checkedMulti(facet).slice(5, checkedMulti(facet).length)" @click="changeFacet(facet, $event)" :key="item.name">
                                <input :id="item.name" :name="item.name" type="checkbox" v-bind:value="item.name" :checked="item.checked"/>
                                <label :for="item.name">{{ item.name }} ({{ item.count }})</label>
                            </div>
                        </template>

                    </PanelMoreLess>

                    <PanelMoreLess v-if="facet.short != 'date' && facet.multi != true" :visible_length="5" :items_length="checkedFacets(facet).length" message_more="More" message_less="Less">

                        <template slot="default-list">
                            <div class="inputGroup" v-for="item in checkedFacets(facet).slice(0, 5)" @click="changeFacet(facet, $event)" :key="item.name">
                                <input :id="item.name" :name="item.name" type="checkbox" v-bind:value="item.name" :checked="item.checked"/>
                                <label :for="item.name">{{ item.name }} ({{ item.count }})</label>
                            </div>
                        </template>

                        <template slot="extra-list">
                            <div class="inputGroup" v-for="item in checkedFacets(facet).slice(5, checkedFacets(facet).length)" @click="changeFacet(facet, $event)" :key="item.name">
                                <input :id="item.name" :name="item.name" type="checkbox" v-bind:value="item.name" :checked="item.checked"/>
                                <label :for="item.name">{{ item.name }} ({{ item.count }})</label>
                            </div>
                        </template>

                    </PanelMoreLess>

                     <div v-if="facet.short == 'date'" class="slider">
                        <vue-slider @change="logDate" :lazy="true" v-model="user_selection.facets.date" :data="checkedDates" :marks="defineLabels" :tooltip="'always'"></vue-slider>
                     </div>

                    </template>

                </PanelHideShow>

            </div>

        </div>

        <div class="expand" @click="visible_facets = !visible_facets">
            <img src="../assets/images/icon_show_more.png" alt="+" v-show="!visible_facets"/>
            <img src="../assets/images/icon_show_more.png" alt="+" v-show="!visible_facets"/>
            <img src="../assets/images/icon_show_more.png" alt="+" v-show="!visible_facets"/>
            <img src="../assets/images/icon_show_less.png" alt="-" v-show="visible_facets"/>
            <img src="../assets/images/icon_show_less.png" alt="-" v-show="visible_facets"/>
            <img src="../assets/images/icon_show_less.png" alt="-" v-show="visible_facets"/>
        </div>

    </div>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import PanelHideShow from '@/components/PanelHideShow.vue'
    import PanelMoreLess from '@/components/PanelMoreLess.vue'
    import VueSlider from 'vue-slider-component'
    import '../assets/css/variomes.css'
    import shared from '@/components/shared.js'


    export default {
      name: 'Facets',
      components: {
        PanelHideShow,
        PanelMoreLess,
        VueSlider,
      },
      props: {
        publications: {
            type: Array,
            required: true
        },
        collection_name: {
            type: String,
            required: true
        },
      },
      data (){
        return {
            visible_facets: true,
        };
      },      
      computed: {
        ...mapState(['query', 'user_selection', 'filters']),
        sortGroupName(){
            return (this.collection_name+"_sort-group")
        },
        
        highlight_list: function () {
            var checkedNames = []
            var results = []
            for (var i = 0; i < this.user_selection.highlights.length; i++ ) {
                checkedNames.push(this.user_selection.highlights[i])
            }
            for (var j = 0; j < this.filters.highlights.length; j++ ) {
                var item = {};
                item.name = this.filters.highlights[j]
                if (checkedNames.includes(this.filters.highlights[j])) {
                  item.checked = true
                }
                else {
                  item.checked = false
                }
                results.push(item)
            }
            return results
        },
        checkedDates() {

                var list = [];
                for (var d = this.filters.date[0]; d <= this.filters.date[1]; d++) {
                    list.push(d);
                }
                return list

        },
        defineLabels: {
            get(){
                var list = this.checkedDates
                var minDate = Math.min.apply(Math, list)
                var maxDate = Math.max.apply(Math, list)

                var marks =  [minDate, maxDate]

                return marks
            },
            set(value){
                this.value = value
            }
        },
        checkedFacets() {
            return (facet_element) => {

                var facet_name = facet_element.short

                var checkedNames = []

                for (var i = 0; i < this.user_selection.facets[facet_name].length; i++ ) {
                    checkedNames.push(this.user_selection.facets[facet_name][i])
                }

                var copied_pub = this.publications
                copied_pub = copied_pub.filter(shared.filterThis.bind(this, this.user_selection.facets))


                var data = []

                // Retrieve all facets
                for (var j = 0; j < copied_pub.length; j++ ) {

                    for (var p=0; p<copied_pub[j].processed_facets[facet_name].length; p++){
                        data.push(copied_pub[j].processed_facets[facet_name][p])
                    }

                }

                var results = this.createFacetList(data, checkedNames)

                return results
            }
        },
        checkedMulti() {
            return (facet_element) => {

                var facet_name = facet_element.short

                var checkedNames = []

                for (var i = 0; i < this.user_selection.facets[facet_name].length; i++ ) {
                    checkedNames.push(this.user_selection.facets[facet_name][i])
                }

                var copied_pub = this.publications
                var selection = {}
                for (const key in this.user_selection.facets){
                    if (key != facet_name){
                        selection[key] = this.user_selection.facets[key]
                    }
                }
                copied_pub = copied_pub.filter(shared.filterThis.bind(this, selection))

                var data = []

                // Retrieve all facets
                for (var j = 0; j < copied_pub.length; j++ ) {

                    for (var p=0; p<copied_pub[j].processed_facets[facet_name].length; p++){
                        data.push(copied_pub[j].processed_facets[facet_name][p])
                    }

                }

                var results = this.createFacetList(data, checkedNames)

                return results
            }
        },

      },
      methods: {
        ...mapActions(['updateSort', 'updateHighlight', 'updateFacet']),
        updatePreSort(sort, event){
            if (event.target.value != undefined){
                if (sort != this.user_selection.sort){
                    this.$matomo?.trackEvent("Sort by ", sort);
                    this.updateSort(sort)
                }
            }
        },
        shouldHighlight(name){
             if (name == "gene" || name == "variant"){
                return true;
             }
             if (this.query[name] != "" && this.query[name] != null){
                return true;
             }
             return false;
        },
        changeHighlight (event) {
          var updated_list = this.user_selection.highlights
          if (event.target.value != undefined){
              if (event.target.checked) {
                this.$matomo?.trackEvent("Highlight", event.target.value);
                updated_list.push(event.target.value)
              }
              else {
                this.$matomo?.trackEvent("Unhighlight", event.target.value);
                for (var i=0; i < updated_list.length; i++ ) {
                  if (updated_list[i] == event.target.value) {
                    updated_list.splice(i, 1)
                  }
                }
              }
          }
          this.updateHighlight(updated_list)
        },
        createFacetList(data, checkedNames){

            var allItems = {}
            var allCount = {}

            // Retrieve all facets
            for (var j = 0; j < data.length; j++ ) {

                var item = {}
                item.name = data[j]
                if (checkedNames.includes(item.name)) {
                    item.checked = true
                }
                else {
                    item.checked = false
                }
                allItems[item.name] = item
                var count = 1
                if (item.name in allCount){
                    count = allCount[item.name] + 1
                }
                allCount[item.name] = count

            }

            // Sort facets by number
            var items = Object.keys(allCount).map(function(key) {return [key, allCount[key]];});
            items.sort(function(first, second) { return second[1] - first[1];});

           // Return facets
           var results = []
           for (var e=0; e<items.length; e++) {
               item = allItems[items[e][0]]
               item.count = allCount[items[e][0]]
               results.push(item)
           }

           return results

        },
        changeFacet(facet_element, event){

            var facet_name = facet_element.short

            var filter_list = this.user_selection.facets[facet_name]
            if(event.target.value != undefined){
                if (event.target.checked) {
                    this.$matomo?.trackEvent("Add facet ", facet_name+": "+event.target.value);
                    this.$matomo?.trackEvent("Facet type", facet_name);
                    
                    filter_list.push(event.target.value)
                }
                else {
                    this.$matomo?.trackEvent("Remove facet ", facet_name+": "+event.target.value);
                    for (var i = 0; i < filter_list.length; i++ ) {
                    if (filter_list[i] == event.target.value) {
                        filter_list.splice(i, 1)
                    }
                    }
                }
            }
            this.updateFacet({'facet': facet_name, 'list': filter_list })

            this.$router.push({ name: 'Home', query: this.$route.query}).catch(()=>{});
            this.$router.push({ name: 'Home', hash: '#literature-section', query: this.$route.query}).catch(()=>{});

        },
        logDate(){
            var date = this.user_selection.facets.date
            this.$matomo?.trackEvent("Date selection", date.join(','));
        }
       }
    }

</script>

<style scoped lang="scss">
$themeColor: #D00000;

    .facets {
        background-color: #f2f2f2;
        text-align: left;
        padding: 0;
        margin: 0px;
        font-size: 0.85em;
        border-right: 1px solid #ddd;
        display: flex;
    }
    .allfilters{
        float:left;
        padding: 10px;
        width: 200px;
    }
    .expand {
        float: right;
        background-color: #DDD;
        width: 10px;
        padding: 10px 1px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .expand img {
        width: 8px;
    }

    .facets h3{
      margin-bottom: 0px;
      padding-bottom: 0px;
    }


    .inputGroup {
        background-color: #fff;
        display: block;
        margin: 10px 0;
        position: relative;
        border-radius: 10px;

        label {
              padding: 5px 25px 5px 10px;
              width: 100%;
              display: block;
              text-align: left;
              font-size:0.8em;
              color: #3C454C;
              cursor: pointer;
              position: relative;
              z-index: 2;
              transition: color 200ms ease-in;
              overflow: hidden;
                border-radius: 10px;

              &:before {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                content: '';
                background-color: #D00000;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) scale3d(1, 1, 1);
                transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
                opacity: 0;
                z-index: -1;
              }

              &:after {
                width: 15px;
                height: 15px;
                content: '';
                border: 2px solid #D1D7DC;
                background-color: #fff;
                border-radius: 50%;
                z-index: 2;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                transition: all 200ms ease-in;
              }

        }

        input:checked ~ label {
              color: #fff;

              &:before {
                transform: translate(-50%, -50%) scale3d(56, 56, 1);
                opacity: 1;
              }

              &:after {
                background-color: #CCCCCC;
                border-color: #CCCCCC;
              }

        }

        input {
              width: 15px;
              height: 15px;
              order: 1;
              z-index: 2;
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              visibility: hidden;
        }
    }

    .slider{
        margin: 30px 10px;
    }


</style>
