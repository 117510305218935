<template>

    <div class="banner">
        <h1>Variomes</h1>
        <p><i>a  high recall search engine to support the curation of genomic variants</i></p>
    </div>

</template>


<script>

    export default {
      name: 'Banner'
    }

</script>


<style scoped lang="scss">

    .banner{
        background: #D00000;
        color: #FFFFFF;
        padding: 20px 10px 10px 10px;
        margin-bottom: 10px;
    }

</style>
