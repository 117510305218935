<template>

    <PanelService>

        <h2>Upload your file</h2>

        <PanelForm>
            <FileReader @load="file_content = $event"></FileReader>
            <span class="required"> *</span>
            <span v-if="query.vcf == ''" class="required"> File upload is required.</span>
            <br/>

            <span class="info">The file must contain a genetic variant per row. Each row must contain two columns: gene and variant. You can download <a href="/files/example.txt" target="_blank" @click="logExample">here</a> an example file.</span>
            <br/><br/>

             <div v-if="file_content!=''"  class="file-description">

                <div class="row">

                    <div class="col-2">
                        <label>File content:</label><br/>
                    </div>

                    <div class="col-5">
                        <label>{{ variantsCount }} genetic variation<span v-if="variantsCount > 1">s</span> selected</label>
                        <br/>
                        <textarea rows="5" v-model="variantsList"></textarea>
                    </div>

                    <div class="col-5">
                        <label>{{ excludedCount }} row<span v-if="excludedCount > 1">s</span> removed</label>
                        <br/>
                        <textarea rows="5" v-model="excludedList"></textarea>
                    </div>

                </div>
                <br/><br/>

            </div>

        </PanelForm>

        <PatientForm/>
        <SettingsForm/>

    </PanelService>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import PanelService from '@/components/PanelService.vue'
    import PatientForm from '@/components/PatientForm.vue'
    import SettingsForm from '@/components/SettingsForm.vue'
    import PanelForm from '@/components/PanelForm.vue'
    import FileReader from "@/components/FileReader";

    export default {
      name: 'ServiceVar',
      components: {
        PanelService,
        PatientForm,
        SettingsForm,
        PanelForm,
        FileReader
      },
      data() {
        return {
            file_content: "",
            variantsCount: 0,
            variantsList: '',
            excludedCount: 0,
            excludedList: ''
        }
      },
      computed: {
        ...mapState(['query']),
      },
      methods: {
        ...mapActions(['updateVCF']),
        logExample(){
            this.$matomo?.trackEvent("Select example", "file example.txt");
        }
      },
      updated() {
          if (this.file_content != "") {
            const lines = this.file_content.split("\n");
            var variants = []
            var excluded = []
            for (var i in lines){
                var elements = lines[i].split("\t")
                if (elements.length == 2){
                    if (elements[0].match(/^[-0-9a-zA-Z]+$/)){
                        variants.push(elements[0].trim()+" ("+elements[1].trim()+")")
                    }
                    else {
                        excluded.push(lines[i])
                    }
                }
                else {
                    excluded.push(lines[i])
                }
            }
            this.variantsCount = variants.length
            this.variantsList = variants.join('\n')
            this.excludedCount = excluded.length
            this.excludedList = excluded.join('\n')
            this.updateVCF(variants.join(';'))
          }
      }
    }

</script>

<style scoped slang="scss">


</style>
