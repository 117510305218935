<template>

    <div class="form">

        <slot></slot>

    </div>

</template>


<script>

    export default {
      name: 'PanelForm'
    }

</script>

<style scoped lang="scss">

    .form {
        margin-bottom: 20px;
        text-align: left;
    }

    label {
        display: inline-block;
        min-width: 15%;
        padding-right: 10px;
    }
    .short-label {
        min-width: 40px;
        text-align: right;
    }
    .long-label {
        min-width: 505px;
    }

    input {
      width: 250px;
      margin-right: 5px;
    }
    .mini-input {
      width: 20px;
    }
    .short-input {
      width: 100px;
    }
    .long-input {
      width: 400px;
    }

    textarea {
        width: 100%;
    }

    select {
      width: 250px;
      background-color: #FFFFFF;
    }


</style>