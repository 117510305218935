<template>

    <div class="component-container query">

        <slot></slot>

    </div>

</template>

<script>

    export default {
      name: 'PanelService',

    }
</script>

<style scoped slang="scss">

    .query {
        padding: 20px;
        background-color: #F2F2F2;
        border-radius: 10px;
    }

</style>
