<template>
<div class="full-text">
    <a :href="getSource" target="_blank">One Tab View</a>
      <pmca-element :id="buildId"/>

</div>

</template>

<script>
    import { mapState } from 'vuex'

    export default {
      name: 'VariomeFullText',
      props: {
        pmcid: {
            type: String,
            required: true
        }
      },
      computed: {
        ...mapState(['variomes_json', 'user_selection']),
        getTest(){
                return "/test.html?pmcid="+this.pmcid
            },
        getSource(){
              return "https://candy.text-analytics.ch/pmca/index.html?pmcid="+this.pmcid
            },
            buildId(){
              return "PMCA_"+this.user_selection.variant_displayed+"_"+this.pmcid
            }
      },
      mounted() {

        document.querySelector("#"+this.buildId).reset();
        var pmca = document.querySelector("#"+this.buildId)
        var myoptions = {};

        // build extra annotations for variant
        var highlighted_src = "Variants";
        var listVar = [];
        pmca.extraAnnotations = listVar;
        var jsonVariants = this.variomes_json.data[this.user_selection.variant_displayed].normalized_query.variants;
        for (var j=0; j<jsonVariants.length; j++){
          var jsonVariant = jsonVariants[j];
          var a1 = {concept_form:jsonVariant.preferred_term,concept_id:jsonVariant.id,concept_source: highlighted_src, preferred_term: jsonVariant.preferred_term, special_id:"none", type: "none", version: "none",  };
          listVar.push(a1);
          if (jsonVariant.preferred_term != jsonVariant.query_term){
            var a2 = {concept_form:jsonVariant.query_term,concept_id:jsonVariant.id,concept_source: highlighted_src, preferred_term: jsonVariant.preferred_term, special_id:"none", type: "none", version: "none" };
            listVar.push(a2);	
          }
          for (var k=0; k<jsonVariant.terms.length; k++){
            var term = jsonVariant.terms[k];
            var a3 = {concept_form:term,concept_id:jsonVariant.id,concept_source: highlighted_src, preferred_term: jsonVariant.preferred_term, special_id:"none", type: "none", version: "none" };
            listVar.push(a3);
          }
        }

        // add extra annotations, setup filter states and display pmcid
        pmca.extraAnnotations = listVar;
        pmca.defaultForAllFilters = false;
        pmca.stateForFilters = {names:[highlighted_src, "Drugbank", "neXtProt", "NCI Thesaurus"], state:true};
        pmca.fillViewerWithIdAndOptions(this.pmcid, myoptions);

      }

  }

</script>

<style scoped>

.full-text {
    margin-top: 50px;
    text-align: justify;
}

</style>