<template>

    <PanelForm class="centered">

        <p>
            <input v-model="conditions_accepted" :checked="conditions_accepted" class="mini-input" type="checkbox"/>
            <i>I agree that my data are stored on HES-SO servers.</i>
        </p>

        <p v-if="conditions_accepted == false" class="required">You must accept the conditions to use the service.</p>
    </PanelForm>

</template>

<script>

    import { mapActions } from 'vuex'
    import PanelForm from '@/components/PanelForm.vue'

    export default {
      name: 'ConditionForm',
      components: {
        PanelForm
      },
      computed: {
             conditions_accepted: {
                get() { return this.$store.getters.getConditionsStatus; },
                set(updated_choice) { this.$store.dispatch('updateConditionsStatus', updated_choice); }
             }
      },
      methods: {
        ...mapActions(['updateConditionsStatus'])
      }
}

</script>

<style scoped lang="scss">

    .centered{
        text-align: center;
    }

</style>
