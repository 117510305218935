<template>

    <div class="component-container export-container">

        <h3>Link to reload the results</h3>
        <p>Your unique identifier is: {{ unique_id }}</p>
        <p>{{ urls.variomes_gui }}?uniqueId={{ unique_id }}</p>
        <div class="message">
            <img src="../assets/images/icon_warning.png"/>
            <span class="info">This link will only reload the results. Your bookmarked publications are not saved.</span>
        </div>
        <br/>

        <hr>

        <h3>Export your data</h3>
        <p>{{ user_selection.document_count }} document<span v-if="user_selection.document_count>1">s</span> selected for {{ user_selection.variant_count }} variant<span v-if="user_selection.variant_count>1">s</span></p>

        <PanelHideShow class="variant-container" size="small" :expanded_init="false" v-for="(collections, variant) in user_selection.publications" :key="variant">

            <template v-slot:title>{{ variant }}</template>

            <template v-slot:content>

                <div v-for="(documents, collection) in collections" :key="collection">
                    <p>{{ collection }}</p>
                    <table>
                        <tr><th>Identifier</th><th>Title</th><th>Date</th><th>Score</th></tr>
                        <tr v-for="(details, document) in documents" :key="document">
                            <td width="10%">{{ details.id }}</td>
                            <td width="70%">{{ details.title }}</td>
                            <td width="15%">{{ details.date}}</td>
                            <td width="5%">{{ details.score.toFixed(2)}}</td>
                        </tr>
                    </table>
                </div>

            </template>

        </PanelHideShow>

        <button v-if="user_selection.document_count > 0" @click="saveBookmarkJson">Export selected publications in JSON</button>
        <button v-if="user_selection.document_count > 0" @click="saveBookmarkCsv">Export selected publications in CSV (Excel)</button>

    </div>

</template>

<script>

    import { mapState } from 'vuex'
    import PanelHideShow from '@/components/PanelHideShow.vue'

    export default {
      name: 'ExportDisplay',
      components: {
        PanelHideShow,
      },
      computed: {
        ...mapState(['urls', 'user_selection', 'unique_id']),
      },
      methods: {
        saveBookmarkJson() {

            var final_json = {}

            for (var variant in this.user_selection.publications){
                final_json[variant] = {}
                for (var collection in this.user_selection.publications[variant]){
                    final_json[variant][collection] = []
                    for (var doc in this.user_selection.publications[variant][collection]){
                       final_json[variant][collection].push(this.user_selection.publications[variant][collection][doc])
                    }
                }
            }

            var string_json = JSON.stringify(final_json)

            var textToSaveAsBlob = new Blob([string_json], {type:"text/json"});
            var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
            var fileNameToSaveAs = this.unique_id+".json";

            var downloadLink = document.createElement("a");
            downloadLink.download = fileNameToSaveAs;
            downloadLink.innerHTML = "Download File";
            downloadLink.href = textToSaveAsURL;
            downloadLink.onclick = this.destroyClickedElement;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            this.$matomo?.trackEvent("Save", "selection json");
            downloadLink.click();

        },
        saveBookmarkCsv() {

            var final_csv = "variant;collection;doc_id;title;date;score\n"

            for (var variant in this.user_selection.publications){
                for (var collection in this.user_selection.publications[variant]){
                    for (var doc in this.user_selection.publications[variant][collection]){
                        var line = variant+";";
                        line += collection+";";
                        line += this.user_selection.publications[variant][collection][doc]['id']+";"
                        line += this.user_selection.publications[variant][collection][doc]['title']+";"
                        line += this.user_selection.publications[variant][collection][doc]['date']+";"
                        line += this.user_selection.publications[variant][collection][doc]['score']
                        final_csv += line +"\n"
                    }
                }
            }

            var textToSaveAsBlob = new Blob([final_csv], {type:"text/plain"});
            var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
            var fileNameToSaveAs = this.unique_id+".csv";

            var downloadLink = document.createElement("a");
            downloadLink.download = fileNameToSaveAs;
            downloadLink.innerHTML = "Download File";
            downloadLink.href = textToSaveAsURL;
            downloadLink.onclick = this.destroyClickedElement;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            this.$matomo?.trackEvent("Save", "selection csv");
            downloadLink.click();

        },
        destroyClickedElement(event){
            document.body.removeChild(event.target);
        }
      }
    }

</script>

<style scoped lang="scss">

    .export-container {
        padding: 10px 20px 20px 20px;
        background-color: #F2F2F2;
        border-radius: 10px;
        border: 1px #aaa solid;
    }
    .variant-container {
        text-align: left;
        margin-bottom: 40px;
    }
    .export-container button {
        font-weight: bold;
        color: #FFFFFF;
        text-decoration: none;
        background-color: #D00000;
        border-radius: 5px;
        padding: 5px 10px;
        border: solid 1px #D00000;
        margin: 0 5px;
    }
    .export-container button:hover {
        text-decoration: none;
        cursor: pointer;
        color: #CCCCCC;
    }

    .export-container table {
      margin-bottom: 20px;
      border-collapse: collapse;
      width: 100%;
      font-size: 0.8em;
      background-color: #eee
    }

    .export-container td, .export-container th {
      border: 1px solid #ddd;
      padding: 6px;
      text-align: center;
    }

    .export-container tr:nth-child(even){
      background-color: #fff;
    }

    .export-container tr:hover {
      background-color: #ddd;
    }

    .export-container th {
      padding-top: 6px;
      padding-bottom: 6px;
      text-align: center;
      background-color: #D00000;
      color: white;
    }

    .message {
       width: 100%;
       align-items:center;
    }

    img {
        width: 20px;
        margin-right: 5px;
    }

</style>
