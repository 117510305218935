<template>

    <PanelService>

        <h2>Enter your query identifier</h2>

        <PanelForm>

        <label>Query identifier:</label>
        <br/>

        <input  type="text" required  v-on:change="updateQueryId" v-model="query.id"/>
        <span class="required"> *</span>
        <span v-if="query.id == ''" class="required"> Query identifier is required.</span>
        <br/><br/>

        </PanelForm>

    </PanelService>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import PanelService from '@/components/PanelService.vue'
    import PanelForm from '@/components/PanelForm.vue'

    export default {
      name: 'ServiceLit',
      components: {
        PanelService,
        PanelForm,
      },
      computed: {
        ...mapState(['query']),
      },
      methods: {
        ...mapActions(['updateQueryId']),
      }
    }

</script>

<style scoped slang="scss">

    input {
      width: 70%;
    }

</style>
