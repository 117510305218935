<template>

    <label class="text-reader">
        <div class="button-box">
            <img src="../assets/images/icon_upload.png" />  Select your file
            <input type="file" @change="loadTextFromFile">
        </div>
    </label>

</template>

<script>
    import { mapState } from 'vuex'

    export default {
      name: 'FileReader',
      computed: {
        ...mapState(['query']),
      },
      methods: {
        loadTextFromFile(event) {
          const file = event.target.files[0];
          const reader = new FileReader();
          var file_name = file.name;
          file_name = file_name.replace(/\.[^/.]+$/, "");
          this.query.file_name = file_name;
          reader.onload = e => this.$emit("load", e.target.result.trim());
          reader.readAsText(file);
        }
      }
    };

</script>

<style>
    .button-box {
       display: flex;
       align-items:center;
    }
    .text-reader {
      position: relative;
      overflow: hidden;
      display: inline-block;
      border: 1px solid #000;
      background-color: #fff;
      border-radius: 5px;
      padding: 2px;
      cursor: pointer;
      color: #000;
      text-align: center;
      vertical-align: middle;
    }
    .text-reader img {
      width: 15px;
      margin: 5px 10px 5px 5px;
    }
    .text-reader input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

</style>
