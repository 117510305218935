<template>

    <b-tab :disabled="!activeTab" title-item-class="tab-title" @click="onTabChange()">

        <template slot="title">
            {{ collection_title }} ({{ publications.length }} document<span v-if="publications.length>1">s</span>)
        </template>

        <b-card-text class="content">

                <Facets class="facets" :publications="publications" :collection_name="collection_name"/>

                <div class="full-container">

                    <div class="active-filters">
                        <span class="filter-name" v-for="filter in activeFilters" :key="filter.name" >
                            <span class="filter-remove" @click="removeFilter(filter.type, filter.name)">x </span> {{ filter.name }}
                        </span>
                        <span class="filters-remove" v-if="activeFilters.length > 1">
                            <span @click="removeAllFilters">Remove all filters</span>
                        </span>
                    </div>

                    <div class="warning" v-if="collection_name=='ct'"><img class="icon" src="../assets/images/icon_warning.png"/> Clinical trials search engine is a beta version. </div>
                    <div class="warning" v-if="collection_name=='supp'"><img class="icon" src="../assets/images/icon_warning.png"/> Supplementary data search engine is a beta version. </div>

                    <DisplayPublicationTable :collection_title="collection_title" :collection_name="collection_name" :publications="publications" :variant="variant" />

               </div>


        </b-card-text>

    </b-tab>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import Facets from '@/components/Facets.vue'
    import DisplayPublicationTable from '@/components/DisplayPublicationTable.vue'

    export default {
      name: 'DisplayCollection',
      components: {
        Facets,
        DisplayPublicationTable
      },
      props: {
        publications: {
            type: Array,
            required: true
        },
        collection_name: {
            type: String,
            required: true
        },
        collection_title: {
            type: String,
            required: true
        },
        variant: {
            type: String,
            required: true
        }
      },
      computed: {
        ...mapState(['user_selection', 'filters']),
        activeTab(){
            if (this.publications.length > 0){
                return true;
            }
            else {
                return false;
            }
        },
        activeFilters(){
            var actived_filters = []
            for (const key in this.user_selection.facets) {
                var list = this.user_selection.facets[key]
                if (key != "date"){
                    for (var i=0; i<list.length; i++){
                        var item = {}
                        item.name = list[i]
                        item.type = key
                        actived_filters.push(item)
                    }
                }
                else {
                    if (this.filters.date[0] != this.user_selection.facets.date[0] || this.filters.date[1] != this.user_selection.facets.date[1]){
                        var item_date = {}
                        item_date.name = "date range"
                        item_date.type = key
                        actived_filters.push(item_date)
                    }
                }
            }
            return actived_filters;
        },
      },
      methods: {
        ...mapActions(['updateFacet']),
        removeFilter(facet_name, value){
            var facet_name_low = facet_name.toLowerCase()
            this.$matomo?.trackEvent("Remove facet ", facet_name+": "+value);
            if (facet_name_low != "date"){
                var filter_list = this.user_selection.facets[facet_name_low]

                for (var i = 0; i < filter_list.length; i++ ) {
                   if (filter_list[i] == value) {
                     filter_list.splice(i, 1)
                   }
                }
                this.updateFacet({'facet': facet_name_low, 'list': filter_list })
            }
            else{
                this.updateFacet({'facet': 'date', 'list': this.filters.date })
            }

        },
        removeAllFilters(){
        this.$matomo?.trackEvent("Remove all facets ", "all");
          for (var key in this.user_selection.facets){
            this.updateFacet({'facet': key, 'list': [] })
          }
          this.updateFacet({'facet': 'date', 'list': this.filters.date })
        },
        onTabChange(){
          this.$matomo?.trackEvent("Display collection search", this.collection_name);
      },
      }
    }

</script>

<style scoped lang="scss">

    .content {
        display: flex;
    }

    .warning {
        background-color: #FCD299;
        border-radius: 2px;
        padding: 10px 0px;
        margin: 10px;
        display: flex;
        align-items:center;
        justify-content: center;
    }

    .icon {
        width: 20px;
        margin-right: 10px;
    }

    .full-container {
        width: 100%;
        margin: 0 auto;
    }

    .facets {
        float: left;
        margin-right: 20px;
    }

    .active-filters{
      text-align: left;
      margin-bottom: 10px;
    }

    .filter-name {
      background-color: #f2f2f2;
      border-radius: 10px;
      padding: 2px 10px;
      margin-right: 5px;
      font-size: 0.8em;
    }

    .filter-remove {
      color: #AAAAAA;
      border-right: 1px solid #AAAAAA;
      margin-right: 5px;
    }

    .filter-remove:hover {
      cursor:pointer;
      color: #D00000;
    }

    .filters-remove {
      padding: 2px 10px;
      margin-right: 5px;
      font-size: 0.8em;
    }
    .filters-remove:hover {
      cursor:pointer;
      color: #D00000;
    }

</style>
