<template>

    <div class="component-container">

        <div class="title">
            <hr>
            <h2>Variant {{ item.query.genvars }} </h2>
            <hr>
        </div>

        <b-tabs content-class="mt-3" justified pills>
            <DisplayCollection :publications="item.publications.medline" collection_title="MEDLINE" collection_name="medline" :variant="item.query.genvars"/>
            <DisplayCollection :publications="item.publications.pmc" collection_title="PubMed Central" collection_name="pmc" :variant="item.query.genvars"/>
            <DisplayCollection :publications="item.publications.ct" collection_title="Clinical Trials" collection_name="ct" :variant="item.query.genvars"/>
            <DisplayCollection :publications="item.publications.supp" collection_title="Supplementary data" collection_name="supp" :variant="item.query.genvars"/>
        </b-tabs>

    </div>

</template>

<script>

    import { mapState } from 'vuex'
    import DisplayCollection from '@/components/DisplayCollection.vue'

    export default {
      name: 'DisplayLiterature',
      components: {
        DisplayCollection,
      },
      computed: {
        ...mapState(['user_selection', 'variomes_json']),
        item (){
            return (this.variomes_json.data[this.user_selection.variant_displayed])
        },
      },
    }

</script>

<style scoped lang="scss">

    .title {
        background-color: #f2f2f2;
        margin-bottom: 20px;
    }

</style>
