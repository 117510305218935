<template>

    <PanelHideShow :expanded_init="false" size="small" log_name="Advanced options">

        <template v-slot:title>Advanced options</template>

        <template v-slot:content>

            <PanelForm>

                <label>Publication date: </label>
                <label class="short-label">from</label>
                <input class="short-input" type="number" :min="getMinYear" :max="settings.max_date" step="1" :v-model="settings.min_date" :value ="settings.min_date" @change="updateMinDate"/>
                <label class="short-label">to</label>
                <input class="short-input" type="number" :min="settings.min_date" :max="getMaxYear" step="1" :v-model="settings.max_date" :value="settings.max_date" @change="updateMaxDate"/>
                <br/><br/>

                <label>Number of documents to retrieve: </label>
                <input class="short-input" type="number" min="1" step="1" :v-model="settings.nb_doc" :value ="settings.nb_doc" @change="updateNbDoc"/>
                <br/><br/>

                <p>The following elements <b>must</b> be mentioned in the publication:</p>
                <label>Gene:</label>
                <input type="checkbox" class="mini-input" v-model="settings.mandatory_gene" @click="updateMandatoryGene" />
                <br/>
                <label>Variant:</label>
                <input type="checkbox" class="mini-input" v-model="settings.mandatory_variant" @click="updateMandatoryVariant" />
                <br/>
                <label>Disease:</label>
                <input type="checkbox" class="mini-input" v-model="settings.mandatory_disease" @click="updateMandatoryDisease" />
                <br/>
                <br/><br/>

                <p>Expand following elements with synonyms:</p>
                <label>Gene:</label>
                <input type="checkbox" class="mini-input" v-model="settings.synonym_gene" @click="updateSynonymGene" />
                <br/>
                <label>Variant:</label>
                <input type="checkbox" class="mini-input" v-model="settings.synonym_variant" @click="updateSynonymVariant" />
                <br/>
                <label>Disease:</label>
                <input type="checkbox" class="mini-input" v-model="settings.synonym_disease" @click="updateSynonymDisease" />
                <br/>
                <br/><br/>

                <label class="long-label">The following keywords <b>should</b> be mentioned in the publication:</label>
                <input class="long-input" type="text" :v-model="settings.keywords_positive" :value="settings.keywords_positive" @change="updateKeywordsPositive"/>
                <br/>
                <label class="long-label">The following keywords <b>should not</b> be mentioned in the publication:</label>
                <input class="long-input" type="text" :v-model="settings.keywords_negative" :value="settings.keywords_negative" @change="updateKeywordsNegative"/>
                <br/>
                <p class="info">Keywords must be separated by semi-colons.</p>

            </PanelForm>

        </template>

    </PanelHideShow>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import PanelHideShow from '@/components/PanelHideShow.vue'
    import PanelForm from '@/components/PanelForm.vue'

    export default {
      name: 'SettingsForm',
      components: {
        PanelHideShow,
        PanelForm
      },
      computed: {
        ...mapState(['settings']),
        getMaxYear(){
            return new Date().getFullYear();
        },
        getMinYear(){
            return 1900;
        },
      },
      methods: {
        ...mapActions([
            'updateMinDate',
            'updateMaxDate',
            'updateNbDoc',
            'updateMandatoryDisease',
            'updateMandatoryGene',
            'updateMandatoryVariant',
            'updateSynonymDisease',
            'updateSynonymGene',
            'updateSynonymVariant',
            'updateKeywordsNegative',
            'updateKeywordsPositive'
        ])
      }
    }

</script>

<style scoped lang="scss">


</style>
