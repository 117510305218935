import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tutorial.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
    {
    path: '/apis',
    name: 'APIs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/API.vue')
  },
  {
    path: '/privacy',
    component: () => import(/* webpackChunkName: "collections" */ '../views/PrivacyPage.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  hash: false,
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return { selector: to.hash, behavior: 'smooth', }
      }
      else if (savedPosition) {
        return savedPosition;
      }
      else {
        return { x: 0, y: 0 }
      }
  }
})

export default router
